body {
	background-color: $cfourWhite;
	color: $cfourPrimaryColor;

	a {
		outline: none;
		color: $cfourPrimaryColor;

		&:hover {
			color: $cfourPrimaryColor;
		}
	}

	a.text-primary {
		outline: none;
		color: $cfourPrimaryColor !important;

		&:hover {
			color: $cfourPrimaryColor !important;
		}
	}

	.form-control:focus {
		border-color: $cfourTertiaryColor;
	}
}
