.featured-sale-new{

	.featured-category-inner{
		background-color: rgba($cfourSecondaryColor, .9);
	}
	&:hover {
		.featured-category-inner{
		    background: rgba($cfourSecondaryColor, 1);
		}
	}
}