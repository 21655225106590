body > #vue-app {
  background-color: $cfourWhite;
}

.btn.btn-right, .btn > .fa {
  float: none;
}

.section-header {
  background: transparent;
  padding: 5px 0;
  border-bottom: 1px solid $cfourLightGray;
  margin-bottom: 30px !important;

  h2 {
    color: $cfourGrayFont;
    font-weight: 400;
    font-size: 16px;
  }

  a.section-link-all {
    color: $cfourGrayFont;
    right: 0;

    &:hover {
      color: $cfourGrayFont;
    }
  }
}

.cmp-hero {
  .hero-main {
    .carousel {
      .carousel-item {
        .hero-category {
          h2 {
            background: rgba($cfourSecondaryColor, .8) !important;
          }
        }
        &:hover {
          h2 {
            background: $cfourSecondaryColor !important;
          }
        }
      }
      .carousel-indicators {
        li {
          border-color: $cfourPrimaryColor;
          &.active {
            background: $cfourPrimaryColor;
          }
        }
      }
      .carousel-control {
        span:before {
          color: $cfourPrimaryColor;
        }
      }
    }
  }
  .hero-xtras {
    .hero-category {
      .hero-category-headline {
        color: $cfourWhite !important;
        background: #c6c9aecc !important;
      }
      &:hover .hero-category-headline {
        background: $cfourSecondaryColor !important;
      }
    }
  }
}
.sale-new {
  .featured-sale-new > img{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.c4brands {
  .c4brandsheadline {
    margin-bottom: 20px;
    h2 {
      text-transform: uppercase;
      text-align: center;
    }
    hr {
      width: 175px;
      border-color: $cfourPrimaryColor;
    }
  }
  .c4brandsimages.c4grayscalebrands img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .c4brandsimages {
    .c4brandlogo {
      position: relative;
      height: 150px;
      margin-bottom: 20px;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .c4textafterbrands {
    margin-top: 20px;
  }
}